import React from 'react';
import { Flex, Box, Heading } from 'rebass';
import { Helmet } from 'react-helmet';
import PageLoader from '../../components/Page';

const Page = props => {


  //console.log(props.pageContext.pageData);
  //const { title, body,handle } = props.data.pages.nodes[0];

  const pageData = props?.pageContext?.pageData;
  const { title, description, handle } = pageData;
  const body = description
  let description_seo = pageData.description;
  const { storeName } = props.data.store.siteMetadata.gatsbyStorefrontConfig;
  return ( 
    <Flex flexWrap="wrap" mx="auto" >
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false}>
        <meta name={description_seo} content={title} />
      </Helmet>
      <PageLoader pageData={pageData} page={title, body, handle} />
    </Flex>
  );
};

export default Page;
